import React from "react"
import News from "../../../blocks/en/news_page"
import { graphql } from "gatsby"

export const pageQuery = graphql`
query {
  news: newsYaml(slug: { eq: "sushkin_contract_ended" }) {
    ...NewsInfoEn
  }
}`

export default class NewsTextEn extends News {
  renderNews() {
    return (
      <div className="row">
        <div className="col-xl-12">
          <p>On 28 February 2020 the FIFA Dispute Resolution Chamber held that Vyacheslav Sushkin, who began his senior career in Saint - Petersburg, terminated his employment relationship with FC Neftchi Fergana with just cause and that FC Neftchi Fergana is liable to pay to Vyacheslav financial damages.</p>
          <p>FC Neftchi did not comply with the imposed liabilities by 18 June 2020 and, as a result, FIFA sanctioned it with a ban on registering any new players for three consecutive transfer periods. Thus, FC Neftchi would be allowed to register new players only after the damages are paid to Vyacheslav.</p>
          <p>In this case, Vyacheslav Sushkin has been represented by Catenaccio Sports Agency.</p>
        </div>
      </div>
    )
  }
}